<!--
// nuxt-ui/components/Shared/slider-matches/SliderMatchesTurn.vue
-->
<script setup lang="ts">
import SliderMatchesTurnMatch from "~/nuxt-ui/components/Shared/slider-matches/SliderMatchesTurnMatch.vue";
import type { DateTimeFormatOptions } from "@intlify/core-base";
import type { Match } from "~/src/match/domain/models/Match";
import type { Team } from "~/src/team/domain/models/Team";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import type { TDateISO } from "~/src/Shared/DateTypes";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import type { Turn } from "~/src/season-cluster/domain/models/04.Turn";

const { locale } = useI18n();

type Props = {
  name: string;
  date: TDateISO;
  matches: Match[] | Match;
  teams: Record<number, Team>;
};

const props = defineProps<Props>();
const { regionTimeZone } = useRegions();
const { currentSeason } = useCompetitionStore();
const orderedByDateMatches = [...props.matches];

const showGroup = (matches: Match[]): boolean => {
  const groupArr = matches.map(m => m.groupId);
  return [...new Set(groupArr)].length > 1;
};

const findTurn = turnName => {
  let foundTurn = null;

  currentSeason?.phases.forEach(p => {
    if (p.groups) {
      p.groups.forEach(g => {
        if (g.turns) {
          foundTurn = g.turns.find(t => t.name === turnName);
        }
      });
    }
  });

  return foundTurn !== undefined ? foundTurn : "not found";
};

const turn: Turn = findTurn(props.name);

const turnDateOptionsBasic: DateTimeFormatOptions = {
  weekday: "long",
  month: "long",
  day: "numeric",
  timeZone: regionTimeZone,
};

const turnRealStartDate: TDateISO | null = orderedByDateMatches[0].date;

const turnRealEndDate: TDateISO | null = orderedByDateMatches[orderedByDateMatches.length - 1].date;

const turnStartDate = turnRealStartDate
  ? new Date(turnRealStartDate).toLocaleDateString(locale.value, turnDateOptionsBasic)
  : undefined;

const turnEndDate = turnRealEndDate
  ? new Date(turnRealEndDate).toLocaleDateString(locale.value, turnDateOptionsBasic)
  : undefined;

const setTurnDate = () => {
  if (turnStartDate === turnEndDate) {
    return turnStartDate;
  } else {
    const dayStart = turnRealStartDate
      ? new Date(turnRealStartDate).toLocaleDateString(locale.value, {
          weekday: "long",
          day: "numeric",
          timeZone: regionTimeZone,
        })
      : undefined;

    const dayStartFirstWord =
      dayStart && locale.value === "es" ? dayStart.split(" ")[0] + ", " + dayStart.split(" ")[1] : dayStart;

    const dayEnd = turnRealEndDate
      ? new Date(turnRealEndDate).toLocaleDateString(locale.value, turnDateOptionsBasic)
      : undefined;

    const monthStart = turnRealStartDate ? new Date(turnRealStartDate).getMonth() : undefined;
    const monthEnd = turnRealEndDate ? new Date(turnRealEndDate).getMonth() : undefined;

    if (monthStart !== monthEnd) {
      return `${turnStartDate} - <span class="first-letter:uppercase inline-block">${turnEndDate}</span>`;
    } else {
      return `${dayStartFirstWord} - <span class="first-letter:uppercase inline-block" >${dayEnd}</span>`;
    }
  }
};

const turnDate = setTurnDate();
</script>

<template>
  <div class="turn-wrapper">
    <div class="turn-info-wrapper">
      <p class="turn-date" v-html="turnDate"></p>
      <div class="turn-name">{{ name }}</div>
    </div>
    <div class="mt-2 flex gap-1">
      <slider-matches-turn-match
        v-for="match in orderedByDateMatches"
        :key="match.id"
        :match="match"
        :teams="teams"
        :show-group="Array.isArray(matches) && showGroup(matches)"
      ></slider-matches-turn-match>
    </div>
  </div>
</template>

<style scoped>
.turn-wrapper {
  @apply py-3.5 px-5 border-r-2 border-gray-800 min-w-max max-w-max relative;
}

.turn-info-wrapper {
  @apply flex gap-2 items-center justify-start sticky left-6 top-0 pr-4 max-w-max;
}

.turn-date {
  @apply text-gray-300 text-sm font-medium first-letter:uppercase;
}

.turn-name {
  @apply border px-1.5 rounded border-gray-500 justify-center items-center text-center text-gray-500 text-[10px] font-normal leading-[15px];
}
</style>
